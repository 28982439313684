import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const NotValidUser = () => {
    useEffect(() => {
        localStorage.removeItem('FormData'); // Removes the item with the key 'FormData'
        localStorage.removeItem('UserData'); // Removes the item with the key 'UserData'    
    }, []);

    return (<>
        <div className="container" style={{ maxWidth: '485px' }}>
            <div className="row align-items-stretch no-gutters contact-wrap">
                <div className="col-md-12">
                    <div className="form h-100">
                        <h5 class="card-title" style={{
                            textAlign: 'center',
                            margin: '10px',
                            fontSize: '26px'
                        }}>Not Valid User</h5>
                        <div style={{ textAlign: 'center' }}>

                            <a href="/login" className="btn btn-primary">Login</a>
                        </div>
                        <div id="form-message-warning mt-4"></div>
                        {/* <div id="form-message-success">
                            Your message was sent, thank you!
                        </div> */}

                    </div>
                </div>
            </div>


        </div>




    </>
    )
}
export default NotValidUser;