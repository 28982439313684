import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Director from './pages/director/Director';
import Llp from './pages/llp/Llp';
import Main from './pages/main/Main';
import Opc from './pages/opc/Opc';
import Others from './pages/others/Others';
import Private from './pages/private/Private';
import PublicComapny from './pages/public/Public';
import Shareholder from './pages/shareholder/Shareholder';
import Partner from './pages/partner/Partner';

import Login from './pages/Login';
import GenerateCode from './pages/GenerateCode';
import NotValidUser from './pages/NotValidUser';



function App() {

  const [userValid, setUserValid] = useState('')
  useEffect(() => {
    let x = localStorage.getItem('UserData');
    x = JSON.parse(x);
    setUserValid(x)
  }, [userValid]);


  return (

    <Routes>
      {userValid ? (
        <>
          <Route path='/' element={<Main />} />
          <Route path='*' element={<Main />} />
          <Route path='/llp' element={<Llp />} />
          <Route path='/director' element={<Director />} />
          <Route path='/partner' element={<Partner />} />
          <Route path='/opc' element={<Opc />} />
          <Route path='/others' element={<Others />} />
          <Route path='/private' element={<Private />} />
          <Route path='/public' element={<PublicComapny />} />
          <Route path='/shareholder' element={<Shareholder />} />
          {/* <Route path='/notValidUser' element={<NotValidUser />} /> */}
          <Route path='/generate_code' element={<GenerateCode />} />
          <Route path='/notValidUser' element={<NotValidUser />} />
          
        </>
      ) : (
        <>
          <Route path='*' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/notValidUser' element={<NotValidUser />} />
      

        </>
      )}

      {/* //   <Route path='/login' element={<Login />} /> */}

    </Routes>

  );
}

export default App;
