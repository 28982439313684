import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';
import Swal from 'sweetalert2';

const Public = () => {
    const [isLoader, setIsLoader] = useState(false)
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            proposedName: '',
            proposedRegisteredOffice: '',
            businessDesc: '',
            proposedIssueCapital: '',
            registeredOfficeAddress: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: '',
            otherDetails: "",
            proposedAuthorisedCapital: "",
            registeredOfficeAddressAttachment: "",
            relevanceAttachment: "",
            relevanceName: "",
            rentNocfromOwner: "",

            //
            fileRelevanceName: ''

        }
    })

    const [radioSelect, setRadioSelect] = useState('Directors')

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]



    const onSubmit = async (data) => {
        // setIsLoader(true)
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
        // console.log(x.id);
        // console.log('Form submit', data)


        let fileRegisteredOfficeAddressAttachment = '';
        let fileRentNocfromOwner = '';

        if (getValues('fileRelevanceName')) {
            // console.log('getValues(fileRelevanceName)---------', getValues('fileRelevanceName')[0]);

            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(getValues('fileRelevanceName')[0]).then((res) => {
                data.relevanceAttachment = handlePriview(getValues('fileRelevanceName')[0].name);

            });
        }
        if (getValues('registeredOfficeAddressAttachment')) {
            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(getValues('registeredOfficeAddressAttachment')[0]).then((res) => {
                data.registeredOfficeAddressAttachment = handlePriview(getValues('registeredOfficeAddressAttachment')[0].name);
                //  = fileRegisteredOfficeAddressAttachment;
            });
        }
        if (getValues('rentNocfromOwner')[0]) {
            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(getValues('rentNocfromOwner')[0]).then((res) => {
                data.rentNocfromOwner = handlePriview(getValues('rentNocfromOwner')[0].name);
                // = fileRentNocfromOwner;
            });
        }


        x.entityForm = {
            proposedName: data.proposedName,
            proposedRegisteredOffice: data.proposedRegisteredOffice,
            businessDesc: data.businessDesc,
            proposedIssueCapital: data.proposedIssueCapital,
            registeredOfficeAddress: data.registeredOfficeAddress,
            phoneNo: data.phoneNo,
            emailAddress: data.emailAddress,
            noOfDirectors: JSON.parse(data.noOfDirectors),
            noOfShareholders: JSON.parse(data.noOfShareholders),
            otherDetails: data.otherDetails,
            proposedAuthorisedCapital: data.proposedAuthorisedCapital,
            registeredOfficeAddressAttachment: data.registeredOfficeAddressAttachment,
            relevanceAttachment: data.relevanceAttachment,
            relevanceName: data.relevanceName,
            rentNocfromOwner: data.rentNocfromOwner
        };

        // console.log(data);

        AddRegisterCustomer(x)
            .then((data) => {
                // console.log(data);
                setIsLoader(false)
                localStorage.setItem('FormData', JSON.stringify(data))

                if (data.directors != '0') {
                    navigate('/director')
                }
                else if (data.shareholder != '0') {
                    navigate('/shareholder')
                }
                else {
                    alert('no director and shareholder selected')
                }

            })
            .catch((error) => console.log(error));

    }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };

    const [base64StringRelevanceName, setBase64StringRelevanceName] = useState('');
    const handleFileChangeRelevanceName = (e) => {
        // console.log(e.target.files[0].type);

        // if (!(e.target.files[0]) && (e.target.files[0]?.type != "application/pdf")) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Oops...",
        //         text: "Please upload PDF file!"
        //     });
        //     return
        // }
        // else {
        //     // setBase64StringRelevanceName(e.target.files[0])
        // }
        setValue('fileRelevanceName', e.target.files[0])
    };

    const [base64StringRegisteredOfficeAddressAttachment, setBase64StringRegisteredOfficeAddressAttachment] = useState('');
    const handleFileChangeRegisteredOfficeAddressAttachment = async (e) => {
        if (!(e.target.files[0])) {
            return
        } else {
            setBase64StringRegisteredOfficeAddressAttachment(e.target.files[0])
        }
    };

    const [base64StringRentNocfromOwner, setBase64StringRentNocfromOwner] = useState('');
    const handleFileChangeRentNocfromOwner = async (e) => {
        if (!(e.target.files[0])) {
            return
        } else {
            setBase64StringRentNocfromOwner(e.target.files[0])
        }
    };


    return (
        <div className="container mt-2">
            <h5 class="card-title" style={{
                textAlign: 'center',
                margin: '10px',
                fontSize: '26px'
            }}>Public Company</h5>
            {isLoader ? (
                <div className='container'>
                    <div className=" loader"></div>
                </div>
            ) : (

                <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group row">
                        <div className='col-md-6'>
                            <label className="form-label" for="ProposedNameInput">Proposed Name</label>
                            <input {...register("proposedName", {
                                required: true,
                            })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                            {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Proposed Name is required</small>)}
                        </div>

                        <div className="form-group col-md-12 d-flex justify-content-between" >
                            <div>
                                <label className="form-label" for="NameRelevanceTextArea" >Name Relevance (Description Of Choosen Name)</label>
                                {/* <input type="text" {...register("relevanceName", {
                                required: true,
                            })} name="description of choosen name" placeholder='descriptio of choosen name' className="form-control" id="file-input" for="file-input" /> */}
                                <input type='text' placeholder='Name Relevance' className="form-control" {...register("relevanceName", {
                                    required: true,
                                })} />
                                {errors.relevanceName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Name Relevance is required</small>)}
                            </div>
                            <div>
                                <label className="form-label" for="NameRelevanceTextArea" > </label>
                                <input type="file" accept="application/pdf"
                                    {...register("fileRelevanceName", {
                                        required: true,
                                        // validate: (value) => {
                                        //     const file = value[0];
                                        //     if (file && file.type !== "application/pdf") {
                                        //         return 'Uploaded file must be a PDF!';
                                        //     }
                                        //     return true;
                                        // }
                                    })}

                                    className="form-control"
                                />
                                {errors.fileRelevanceName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
                                </small>)}

                            </div>

                        </div>


                    </div>
                    <div className="form-group col-md-12">
                        <label className="form-label" for="ProposedRegisteredOfficeTextArea">Proposed Registered Office</label>
                        <textarea {...register("proposedRegisteredOffice", {
                            required: true,
                        })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Proposed Registered Office" />
                        {errors.proposedRegisteredOffice && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">Proposed Registered Office is required</small>
                        )}
                    </div>


                    <div className='row'>
                        <label className="form-label" for="NameRelevanceTextArea" >Registered Office Address Proof (e.g. Electricity Bill, Mobile Bill, Tax Receipt: Not Older Than Two Months)</label>
                        <div className='col-md-6'>
                            <input type="file"
                                accept="application/pdf"
                                {...register("registeredOfficeAddressAttachment", {
                                    required: true,
                                    // validate: (value) => {
                                    //     const file = value[0];
                                    //     if (file && file.type !== "application/pdf") {
                                    //         return 'Uploaded file must be a PDF!';
                                    //     }
                                    //     return true;
                                    // }
                                })}
                                //  onChange={handleFileChangeRegisteredOfficeAddressAttachment}
                                className="form-control col" />
                            {errors.registeredOfficeAddressAttachment && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted"> Please select a PDF file! </small>
                            )}
                        </div>
                        <div className='col-md-6'>

                            <input type='text' placeholder='Enter Registered Office Address Proof' className="form-control col" {...register("registeredOfficeAddress", {
                                required: true,
                            })} />
                            {errors.registeredOfficeAddress && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">Registered Office Address Proof is required</small>
                            )}
                        </div>


                    </div>


                    <div className="form-group col-md-6">
                        <label className="form-label" for="NameRelevanceTextArea" >In Case of Lease/Rent: NOC from Owner</label>
                        <input type="file" className="form-control"
                            accept="application/pdf"
                            {...register("rentNocfromOwner", {
                                required: true,
                                // validate: (value) => {
                                //     const file = value[0];
                                //     if (file && file.type !== "application/pdf") {
                                //         return 'Uploaded file must be a PDF!';
                                //     }
                                //     return true;
                                // }
                            })}
                        //  onChange={handleFileChangeRegisteredOfficeAddressAttachment}
                        />
                        {errors.rentNocfromOwner && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted"> Please select a PDF file! </small>
                        )}


                    </div>



                    <div className='col-md-6'>
                        <label className="form-label" for="ProposedNameInput">Main Object Clause: Business to be carried out</label>
                        <input placeholder='Enter Main Object Clause' {...register("businessDesc", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp"
                        //  placeholder="Enter Proposed Name" 
                        />

                        {errors.businessDesc && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Main Object Clause: Business to be carried out is required</small>)}
                    </div>


                    <div className="form-group col-md-6">
                        <label className="form-label" for="ProposedAuthorizedCapitalInput">Proposed Authorized Capital Minimum INR 1 Lakh </label>
                        <input
                            {...register("proposedAuthorisedCapital", {
                                required: "Proposed Authorized Capital is required",
                                validate: {
                                    isNumber: (value) => !isNaN(value) || "Value must be a number",
                                    isGreaterThanOrEqualToOneLakh: (value) => {
                                        const numValue = parseFloat(value);
                                        return (numValue >= 100000) || "Value must be at least 1 Lakh";
                                    },
                                },
                            })}
                            type="text"
                            name="proposedAuthorisedCapital"
                            className="form-control"
                            id="ProposedNameInput"
                            aria-describedby="ProposedNameHelp"
                            placeholder="Enter Proposed Authorized Capital"
                        />

                        {errors.proposedAuthorisedCapital && (
                            <small
                                id="ProposedNameHelp"
                                style={{ color: "#dc3545" }}
                                className="form-text text-muted"
                            >
                                {errors.proposedAuthorisedCapital.message}
                            </small>
                        )}


                    </div>

                    <div className="form-group col-md-5">
                        <label className="form-label" for="ProposedIssuedCapitalInput">Proposed Issued Capital and Subscribed Capital</label>
                        <input {...register("proposedIssueCapital", {
                            required: true,
                        })} type="number"
                            min="0"
                            className="form-control" id="ProposedIssuedCapitalInput" aria-describedby="ProposedIssuedCapitalHelp"
                            placeholder="Enter Proposed Issued Capital and Subscribed Capital"
                        />
                        {errors.proposedIssueCapital && (<small id="ProposedIssuedCapitalHelp" className="form-text text-muted">Proposed Issued Capital and Subscribed Capital is required</small>
                        )}
                    </div>

                    <div className="form-group col-md-12">
                        <label className="form-label" for="ProposedIssuedCapitalInput">Other Details</label>
                        <textarea {...register("otherDetails", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedIssuedCapitalInput" aria-describedby="ProposedIssuedCapitalHelp"
                            placeholder="Enter Other Details"
                        />
                        {errors.otherDetails && (<small id="ProposedIssuedCapitalHelp" className="form-text text-muted">Other Details is required</small>
                        )}
                    </div>



                    <div className='row'>
                        <div className="form-group col-md-6">
                            <label className="form-label" for="PhonenoInput"> Mobile Number</label>
                            <input
                                {...register("phoneNo", {
                                    required: "Mobile Number is required",
                                    validate: {
                                        isValidNumber: value => {
                                            const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
                                            return phoneRegex.test(value) || "Mobile Number must be a 10-digit number";
                                        }
                                    }
                                })}
                                min="0"
                                type="text" // Change type to text to prevent scientific notation for large numbers
                                className="form-control"
                                id="PhonenoInput"
                                aria-describedby="PhonenoHelp"
                                placeholder="Enter Mobile Number"
                            />
                            {errors.phoneNo && (
                                <small id="PhonenoHelp" className="form-text text-muted">
                                    {errors.phoneNo.message}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" for="emailInput"> Email ID</label>
                            <input
                                {...register("emailAddress", {
                                    required: "Email address is required",
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email regex
                                        message: "Please enter a valid email address"
                                    }
                                })}
                                type="email"
                                className="form-control"
                                id="emailInputInput"
                                aria-describedby="emailInputHelp"
                                placeholder="Enter Email"
                            />
                            {errors.emailAddress && (
                                <small id="emailInputHelp" className="form-text text-muted">
                                    {errors.emailAddress.message}
                                </small>
                            )}
                        </div>

                    </div>

                    <div className='row'>

                        <div className="form-group col-md-6">
                            <label className="form-label" for="selectDirectors">Number of Directors</label>

                            <select className="form-control" id="budget" name="budget"
                                {...register("noOfDirectors", {
                                    required: true,
                                })}>
                                <option selected value="">--Select--</option>

                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            {errors.noOfDirectors && (<small id="noOfDirectorsSelectHelp" className="form-text text-muted">Please select number of Directors</small>
                            )}

                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label" for="selectShareholders">Number of Shareholders</label>

                            <select className="form-control" id="budget" name="budget"
                                {...register("noOfShareholders", {
                                    required: true,
                                })}>
                                <option selected value="">--Select--</option>
                                <option value="1">1 </option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </select>
                            {errors.noOfShareholders && (<small id="noOfShareholdersSelectHelp" className="form-text text-muted">Please select number of Shareholders</small>
                            )}
                        </div>

                    </div>


                    <div className="form-group col-md-12" style={{ textAlign: 'end', marginTop: '6px' }}>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>
            )
            }


        </div>)
}

export default Public