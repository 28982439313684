import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import { AddRegisterCustomer } from "../../helpers/PostRquest/postRquest";
import uploadFileToBlob, { handlePriview } from "../../helpers/azureBlob";
// import { getValues } from "@testing-library/user-event/dist/utils";

const Llp = () => {
  const navigate = useNavigate();

  const [base64String, setBase64String] = useState("");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      proposedName: "",
      proposedRegisteredOffice: "",
      relevanceName: "",
      businessDesc: "",
      proposedAuthorisedCapital: "",
      proposedIssueCapital: "",
      registeredOfficeAddress: "",
      registeredOfficeAddressAttachment: "",
      phoneNo: "",
      emailAddress: "",
      relevanceAttachment: "",
      rentNocfromOwner: "",
      otherDetails: "",
      noOfPartners: "",
    },
  });

  const onSubmit = async (data) => {
    // console.log("Form submit", data);
    let x = localStorage.getItem("FormData");
    x = JSON.parse(x);
    let fileRelevanceName = '';
    let fileRegisteredOfficeAddressAttachment = '';
    let fileRentNocfromOwner = '';

    if (getValues('relevanceAttachment')) {
      // *** UPLOAD TO AZURE STORAGE ***
      const blobUrl = await uploadFileToBlob(getValues('relevanceAttachment')[0]).then((res) => {
        data.relevanceAttachment = handlePriview(getValues('relevanceAttachment')[0].name);

      });
    }

    if (getValues('registeredOfficeAddressAttachment')) {
      // *** UPLOAD TO AZURE STORAGE ***
      const blobUrl = await uploadFileToBlob(getValues('registeredOfficeAddressAttachment')[0]).then((res) => {
        data.registeredOfficeAddressAttachment = handlePriview(getValues('registeredOfficeAddressAttachment')[0].name);

      });
    }
    if (getValues('rentNocfromOwner')) {
      // *** UPLOAD TO AZURE STORAGE ***
      const blobUrl = await uploadFileToBlob(getValues('rentNocfromOwner')[0]).then((res) => {
        data.rentNocfromOwner = handlePriview(getValues('rentNocfromOwner')[0].name);

      });
    }

    x.llpCompany = {
      proposedName: data.proposedName,
      proposedRegisteredOffice: data.proposedRegisteredOffice,
      relevanceName: data.relevanceName,
      businessDesc: data.businessDesc,
      proposedAuthorisedCapital: data.proposedAuthorisedCapital,
      proposedIssueCapital: data.proposedIssueCapital,
      registeredOfficeAddress: data.registeredOfficeAddress,
      registeredOfficeAddressAttachment: data.registeredOfficeAddressAttachment,
      phoneNo: data.phoneNo,
      emailAddress: data.emailAddress,
      relevanceAttachment: data.relevanceAttachment,
      rentNocfromOwner: data.rentNocfromOwner,
      otherDetails: data.otherDetails,
      noOfPartners: parseInt(data.noOfPartners),
    };

    AddRegisterCustomer(x)
      .then((data) => {
        // console.log(data);
        localStorage.setItem("FormData", JSON.stringify(data));

        if (data.partners != "0") {
          navigate("/partner");
        } else {
          alert("no partners selected");
        }
      })
      .catch((error) => console.log(error));
  };

  const [base64StringRelevanceName, setBase64StringRelevanceName] = useState('');
  const handleFileChangeRelevanceName = async (e) => {
    if (!(e.target.files[0])) {
      return
    } else {
      setBase64StringRelevanceName(e.target.files[0])
    }
  };

  const [base64StringRegisteredOfficeAddressAttachment, setBase64StringRegisteredOfficeAddressAttachment] = useState('');
  const handleFileChangeRegisteredOfficeAddressAttachment = async (e) => {
    if (!(e.target.files[0])) {
      return
    } else {
      setBase64StringRegisteredOfficeAddressAttachment(e.target.files[0])
    }
  };

  const [base64StringRentNocfromOwner, setBase64StringRentNocfromOwner] = useState('');
  const handleFileChangeRentNocfromOwner = async (e) => {
    if (!(e.target.files[0])) {
      return
    } else {
      setBase64StringRentNocfromOwner(e.target.files[0])
    }
  };

  return (
    <div className="container mt-2">
      <h5
        class="card-title"
        style={{
          textAlign: "center",
          margin: "10px",
          fontSize: "26px",
        }}
      >
        LLP Company
      </h5>
      <form
        id="incorporationForm"
        className="row"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/*  */}
        <div className="form-group row">
          <div className="col-md-6">
            <label className="form-label" for="ProposedNameInput">Proposed Name</label>
            <input
              {...register("proposedName", {
                required: true,
              })}
              type="text"
              className="form-control"
              id="ProposedNameInput"
              aria-describedby="ProposedNameHelp"
              placeholder="Enter Proposed Name"
            />

            {errors.proposedName && (<small id="ProposedNameInput" className="form-text text-muted">Proposed Name is required</small>
            )}
          </div>
        </div>
        {/*  */}
        <div className="form-group col-md-12">
          <label className="form-label" for="NameRelevanceTextArea">
            Name Relevance (Description Of Chosen Name)
          </label>
          <div className=" row">
            <div className="col-md-6">
              <input
                type="text"
                name="relevanceName"
                {...register("relevanceName", {
                  required: true,
                })}
                placeholder="Enter Name Relevance"
                className="form-control"
                id="file-input"
                for="file-input"
              />
              {errors.relevanceName && (<small id="CorporatePartnerHelp" className="form-text text-muted">Name Relevance is required</small>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="file"

                accept="application/pdf"
                {...register("relevanceAttachment", {
                  required: true,
                  // validate: (value) => {
                  //     const file = value[0];
                  //     if (file && file.type !== "application/pdf") {
                  //         return 'Uploaded file must be a PDF!';
                  //     }
                  //     return true;
                  // }
                })}

                className="form-control"
              />
              {errors.relevanceAttachment && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
              </small>)}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="col-md-6">
          <label className="form-label" for="ProposedNameInput">Proposed Registered Office</label>
          <input
            {...register("proposedRegisteredOffice", {
              required: true,
            })}
            name="proposedRegisteredOffice"
            type="text"
            className="form-control"
            id="ProposedNameInput"
            aria-describedby="ProposedNameHelp"
            placeholder="Enter Proposed Registered Office"
          />

          {errors.proposedRegisteredOffice && (
            <small
              id="ProposedNameHelp"
              style={{ color: "#dc3545" }}
              className="form-text text-muted"
            >
              Proposed Registered Office is required
            </small>
          )}
        </div>
        {/*  */}
        <div className="form-group col-md-12">
          <label className="form-label" for="NameRelevanceTextArea">
            Registered Office Address Proof  (e.g. Electricity Bill, Mobile Bill, Tax Receipt: Not Older Than Two Months)
          </label>
          <div className="row">
            <div className="col-md-6">
              <input
                {...register("registeredOfficeAddress", {
                  required: true,
                })}
                type="text"
                name="registeredOfficeAddress"
                placeholder="Enter Registered Office Address Proof"
                className="form-control"
                id="file-input"
                for="file-input"
              />
              {errors.registeredOfficeAddress && (
                <small
                  id="ProposedNameHelp"
                  style={{ color: "#dc3545" }}
                  className="form-text text-muted"
                >
                  Registered Office Address Proof is required
                </small>
              )}
            </div>
            <div className="col-md-6">

              <input
                type="file"
                className="form-control"
                accept="application/pdf"
                {...register("registeredOfficeAddressAttachment", {
                  required: true,
                  // validate: (value) => {
                  //     const file = value[0];
                  //     if (file && file.type !== "application/pdf") {
                  //         return 'Uploaded file must be a PDF!';
                  //     }
                  //     return true;
                  // }
                })}


              />
              {errors.registeredOfficeAddressAttachment && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
              </small>)}
            </div>
          </div>
        </div>
        {/*  */}

        {/*  */}
        <div className="col-md-6">
          <label className="form-label" for="ProposedNameInput">
            Main Object Clause: Business to be carried out
          </label>
          <input
            {...register("businessDesc", {
              required: true,
            })}
            type="text"
            name="businessDesc"
            className="form-control"
            id="ProposedNameInput"
            aria-describedby="ProposedNameHelp"
            placeholder="Enter Main Object Clause"
          />

          {errors.businessDesc && (
            <small
              id="ProposedNameHelp"
              style={{ color: "#dc3545" }}
              className="form-text text-muted"
            >
              Main Object Clause: Business to be carried out is required
            </small>
          )}
        </div>
        {/*  */}

        <div className="col-md-6">
          <label className="form-label" for="ProposedNameInput">
            Proposed Authorized Capital Minimum INR 1 Lakh
          </label>
          <input
                         {...register("proposedAuthorisedCapital", {
                            required: "Proposed Authorized Capital is required",
                            validate: {
                              isNumber: (value) => !isNaN(value) || "Value must be a number",
                              isGreaterThanOrEqualToOneLakh: (value) => {
                                const numValue = parseFloat(value);
                                return (numValue >= 100000) || "Value must be at least 1 Lakh";
                              },
                            },
                          })}
                          type="text"
                          name="proposedAuthorisedCapital"
                          className="form-control"
                          id="ProposedNameInput"
                          aria-describedby="ProposedNameHelp"
                          placeholder="Enter Proposed Authorized Capital"
                        />
                        
                        {errors.proposedAuthorisedCapital && (
                          <small
                            id="ProposedNameHelp"
                            style={{ color: "#dc3545" }}
                            className="form-text text-muted"
                          >
                            {errors.proposedAuthorisedCapital.message}
                          </small>
                        )}
        </div>
        {/*  */}
        <div className="form-group col-md-6">
          <label className="form-label" for="NameRelevanceTextArea">
            In Case of Lease/Rent: NOC from Owner
          </label>


          <input
            type="file"
            name="rentNocfromOwner"
            className="form-control"
            accept="application/pdf"
            {...register("rentNocfromOwner", {
              required: true,
              // validate: (value) => {
              //     const file = value[0];
              //     if (file && file.type !== "application/pdf") {
              //         return 'Uploaded file must be a PDF!';
              //     }
              //     return true;
              // }
            })}


          />
          {errors.rentNocfromOwner && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
          </small>)}

        </div>
        <div className="col-md-6">
          <label className="form-label" for="ProposedNameInput">
            Proposed Issued Capital and Subscribed Capital
          </label>
          <input
            {...register("proposedIssueCapital", {
              required: true,
            })}
            name="proposedIssueCapital"
            type="text"
             min="0"
            className="form-control"
            id="ProposedNameInput"
            aria-describedby="ProposedNameHelp"
            placeholder="Enter Proposed Issued Capital and Subscribed Capital"
          />

          {errors.proposedIssueCapital && (
            <small
              id="ProposedNameHelp"
              style={{ color: "#dc3545" }}
              className="form-text text-muted"
            >
              Proposed Issued Capital and Subscribed Capital is required
            </small>
          )}
        </div>
        {/*  */}
        <div className="col-md-12">
          <label className="form-label" for="ProposedNameInput">Other Details</label>
          <textarea
            {...register("otherDetails", {
              required: true,
            })}
            // name="otherDetails"
            type="text"
            className="form-control"
            id="ProposedNameInput"
            aria-describedby="ProposedNameHelp"
            placeholder="Enter Other Details"
          />
          {errors.otherDetails && (
            <small className="form-text text-muted">
              Other Details is required
            </small>
          )}
        </div>
        {/*  */}
        <div className="row">
          <div className="form-group col-md-6">
            <label className="form-label" for="PhonenoInput">Mobile Number</label>
            <input
              {...register("phoneNo", {
                required: "Mobile Number is required",
                validate: {
                  isValidNumber: value => {
                    const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
                    return phoneRegex.test(value) || "Mobile Number must be a 10-digit number";
                  }
                }
              })}
              min="0"
              type="text" // Change type to text to prevent scientific notation for large numbers
              className="form-control"
              id="PhonenoInput"
              aria-describedby="PhonenoHelp"
              placeholder="Enter Mobile Number"
            />
            {errors.phoneNo && (
              <small id="PhonenoHelp" className="form-text text-muted">
                {errors.phoneNo.message}
              </small>
            )}
          </div>
          {/*  */}
          <div className="form-group col-md-6">
            <label className="form-label" for="emailInput"> Email ID</label>
            <input
              {...register("emailAddress", {
                required: "Email address is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email regex
                  message: "Please enter a valid email address"
                }
              })}
              type="email"
              className="form-control"
              id="emailInputInput"
              aria-describedby="emailInputHelp"
              placeholder="Enter Email"
            />
            {errors.emailAddress && (
              <small id="emailInputHelp" className="form-text text-muted">
                {errors.emailAddress.message}
              </small>
            )}
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="form-group col-md-6">
            <label className="form-label" for="selectDirectors">
              Number of Partners
            </label>
            <select
              class="form-select"
              {...register("noOfPartners", { required: true })}
              aria-label="Default select example"
            >
              <option value="">
                Select
              </option>

              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            {errors.noOfPartners && (
              <small id="CorporatePartnerHelp" className="form-text text-muted">
                Please Select Number of Partners
              </small>
            )}
          </div>
        </div>
        {/*  */}
        <div
          className="form-group col-md-12"
          style={{ textAlign: "end", margin: "4px" }}
        >
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Llp;
