import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';

const Opc = () => {
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const [base64String, setBase64String] = useState('');
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            personName: "",
            proposedName: "",
            proposedRegisteredOffice: "",
            attachment: "",

            proposedCapital: "",
            registeredOfficeAddress: "",
            phoneNo: "",
            emailAddress: "",
            noOfDirectors: "",
            noOfShareholders: "",
            businessActivity: "",
            detailsOfProprietor: ""
        }
    })

    // const [radioSelect, setRadioSelect] = useState('ManagingPartners')

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]

    const onSubmit = async (data) => {
        // console.log('Form submit', data)
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);

        if (getValues('attachment')) {
            // *** UPLOAD TO AZURE STORAGE ***
            console.log('getValues(fileRelevanceAttachment)[0]',getValues('attachment')[0]);
            
            const blobUrl = await uploadFileToBlob(getValues('attachment')[0]).then((res) => {
                data.attachment = handlePriview(getValues('attachment')[0].name);

            });
        }

        x.opcCompany = {
            personName: data.personName,
            proposedName: data.proposedName,
            proposedRegisteredOffice: data.proposedRegisteredOffice,
            attachment: data.attachment,
            proposedCapital: data.proposedCapital,
            registeredOfficeAddress: data.registeredOfficeAddress,
            phoneNo: data.phoneNo,
            emailAddress: data.emailAddress,
            businessActivity: data.businessActivity,
            detailsOfProprietor: data.detailsOfProprietor,
            noOfDirectors: parseInt(data.noOfDirectors),
            noOfShareholders: parseInt(data.noOfShareholders)
        };
        AddRegisterCustomer(x)
            .then((data) => {
                // console.log(data);
                localStorage.setItem('FormData', JSON.stringify(data))

                if (data.directors != '0') {
                    navigate('/director')
                }
                else if (data.shareholder != '0') {
                    navigate('/shareholder')
                }
                else {
                    alert('no director and shareholder selected')
                }

            })
            .catch((error) => console.log(error));

    }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };

    const handleFileChange = async (e) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setBase64String(reader.result.split(',')[1]); // Get Base64 string without the data URL prefix
        //     };
        //     reader.readAsDataURL(file);
        // }

        if (!(e.target.files[0])) {
            return
        } else {
            setBase64String(e.target.files[0])
        }
    };

    return (
        <div className="container mt-2">
            <h5 class="card-title" style={{
                textAlign: 'center',
                margin: '10px',
                fontSize: '26px'
            }}>OPC Form</h5>
            <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>

                <div className='col-md-6'>
                    <label className="form-label" for="ProposedNameInput">Person Name</label>
                    <input {...register("personName", {
                        required: true,
                    })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Person Name" />

                    {errors.personName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Person Name is required</small>)}
                </div>

                <div className="form-group row">
                    <div className='col-md-6'>
                        <label className="form-label" for="ProposedNameInput">Proposed Name</label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                        {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Proposed Name is required</small>)}
                    </div>
                    {/* <div className='col-md-6'>
                        <label className="form-label"  for="ProposedNameInput"></label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />
                    </div> */}
                </div>

                <div className="form-group col-md-12">
                    <label className="form-label" for="ProposedRegisteredOfficeTextArea">Proposed Registered Office</label>
                    <textarea {...register("proposedRegisteredOffice", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Proposed Registered Office" />
                  
                  {errors.proposedRegisteredOffice && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Proposed Registered Office is required</small>)}
                </div>

                <div className="form-group col-md-6">
                    <label className="form-label" for="NameRelevanceTextArea">Add Attachment</label>
                    <input type="file" accept="application/pdf" {...register("attachment", {
                        required: true,
                    })} name="attachment" className="form-control" id="file-input" for="file-input" />
                    {errors.attachment && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Attachment is required</small>)}
                </div>

                <div className="form-group col-md-6">
                    <label className="form-label" for="ProposedCaptialInput">Proposed Captial</label>
                    <input {...register("proposedCapital", {
                        required: true,
                    })} type="number" className="form-control" id="ProposedCaptialInput" aria-describedby="ProposedCaptial" placeholder="Enter Proposed Captial" />
                   {errors.proposedCapital && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Proposed Captial is required</small>)}
                
                </div>

                <div className="form-group col-md-12">
                    <label className="form-label" for="AddressTextArea">Registered Address</label>
                    <textarea {...register("registeredOfficeAddress", {
                        required: true,
                    })} className="form-control" aria-describedby="AddressHelp" id="AddressTextArea" rows="3" placeholder="Enter Registered Address" />
                    {errors.registeredOfficeAddress && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Registered Address is required</small>)}
                
                </div>
                <div className='row'>
                    <div className="form-group col-md-6">
                        <label className="form-label" for="PhonenoInput">Registered Mobile Number</label>
                        <input
                {...register("phoneNo", {
                    required: "Mobile Number is required",
                    validate: {
                        isValidNumber: value => {
                            const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
                            return phoneRegex.test(value) || "Mobile Number must be a 10-digit number";
                        }
                    }
                })}
                min="0"
                type="number" // Change type to text to prevent scientific notation for large numbers
                className="form-control"
                id="PhonenoInput"
                aria-describedby="PhonenoHelp"
                placeholder="Enter Mobile Number"
            />
            {errors.phoneNo && (
                <small id="PhonenoHelp" className="form-text text-muted">
                    {errors.phoneNo.message}
                </small>
            )}
                
                    </div>
                    <div className="form-group col-md-6">
                        <label className="form-label" for="emailInput">Registered Email</label>
                        <input
                {...register("emailAddress", {
                    required: "Email address is required",
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email regex
                        message: "Please enter a valid email address"
                    }
                })}
                type="email"
                className="form-control"
                id="emailInputInput"
                aria-describedby="emailInputHelp"
                placeholder="Enter Email"
            />
            {errors.emailAddress && (
                <small id="emailInputHelp" className="form-text text-muted">
                    {errors.emailAddress.message}
                </small>
            )}
                    </div>
                </div>

                <div className='row'>
                    <div className="form-group col-md-6">
                        <label className="form-label" for="BusinessActivityInput">Business Activity</label>
                        <input {...register("businessDesc", {
                            required: true,
                        })} type="text" className="form-control" id="BusinessActivityInput" aria-describedby="BusinessActivityHelp" placeholder="Enter Business Activity" />
                        {errors.businessDesc && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Business Activity is required</small>)}
                    
                    </div>
                    <div className="form-group col-md-6">
                        <label className="form-label" for="ProprietorDetailsInput">Proprietor Details</label>
                        <input {...register("detailsOfProprietor", {
                            required: true,
                        })} type="text" className="form-control" id="ProprietorDetailsInput" aria-describedby="proprietorDetailsHelp" placeholder="Enter Proprietor Details" />
                        {errors.detailsOfProprietor && (<small id="proprietorDetailsHelp" className="form-text text-muted">Proprietor Details is required</small>
                        )}
                    </div>
                </div>

                <div className='row'>
                    {/* {radioSelect == 'Directors' ? ( */}
                    <div className="form-group col-md-6">
                        <label className="form-label" for="selectDirectors">Number of Directors</label>
                        <select class="form-select" {...register("noOfDirectors", { required: true, })} aria-label="Default select example">
                            <option selected value=''>Select</option>

                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        {errors.noOfDirectors && (<small id="emailInputHelp" className="form-text text-muted">Please Select Number of Directors</small>
                        )}
                    </div>

                    {/* <div className="form-group col-md-6">
                        <label className="form-label"  for="selectDirectors">Number of Directors (minimum 2)</label> */}
                    {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                    {/* <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                            onChange={handleChangeDirectors}
                            name="noOfDirectors" // Handle changes
                        /> */}
                    {/* <small id="selectDirectorsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    {/* </div> */}
                    {/* ) : ( */}
                    <div className="form-group col-md-6">
                        <label className="form-label" for="selectShareholders">Number of Shareholders</label>
                        <select class="form-select" aria-label="Default select example" {...register("noOfShareholders", { required: true, })}>
                            <option selected value=''>Select </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                        </select>
                        {errors.noOfShareholders && (<small id="emailInputHelp" className="form-text text-muted">Please select number of shareholders</small>
                        )}
                        {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                        {/* <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions2}
                            onChange={handleChangeShareholders}
                            name="noOfShareholders"  // Handle changes
                        /> */}
                        {/* <small id="selectShareholdersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    {/* )} */}
                </div>



                <div className="form-group col-md-12" style={{ textAlign: 'end', margin: '4px' }}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>
            </form>

        </div>)
}

export default Opc