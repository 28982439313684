import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';

// Define the Others component
const Others = () => {

    const navigate = useNavigate();
    const animatedComponents = makeAnimated();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            registeredOfficeAddress: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: ''
        }
    })

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]

    // const onSubmit = (data) => {
    //     console.log('Form submit', data)
    // if (data.companyType == 'private'){
    // navigate('/private')
    // }

    // AddRegisterCustomer(data)
    // .then((data) => {
    //    console.log(data);
    // })
    // .catch((error) => console.log(error));

    // }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };

    const onSubmit = (data) => {
        // console.log('Form submit', data)
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
        x.otherCompany = {
            registeredOfficeAddress: data.registeredOfficeAddress,
            phoneNo: data.phoneNo,
            emailAddress: data.emailAddress,
            noOfDirectors: parseInt(data.noOfDirectors),
            noOfShareholders: parseInt(data.noOfShareholders)
        };
        AddRegisterCustomer(x)
        .then((data) => {
            // console.log(data);
            localStorage.setItem('FormData', JSON.stringify(data))
               
                if (data.directors != '0') {
                    navigate('/director')
                }
                else if(data.shareholder != '0') {
                    navigate('/shareholder')
                }
                else{
                    alert('no director and shareholder selected')
                }

            })
            .catch((error) => console.log(error));

    }


    return (
        <div>
            <div class="container mt-5">
                <h2 class="text-center mb-4" style={{color:'black'}}>Others Company</h2>
                <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>


                    <div className="form-group ">
                        <label for="regAddress" class="form-label">Registered Address</label>
                        <input type="text" placeholder='Enter Registered Address'  {...register("registeredOfficeAddress", { required: true, })} class="form-control" id="regAddress"  />
                        {errors.registeredOfficeAddress && (<small id="CorporatePartnerHelp" className="form-text text-muted">Please enter registered address</small>
                        )}

                    </div>



                    <div class="mb-3">
                        <label for="phoneInput" class="form-label">Registered Mobile Number</label>
                        <input
                {...register("phoneNo", {
                    required: "Mobile Number is required",
                    validate: {
                        isValidNumber: value => {
                            const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
                            return phoneRegex.test(value) || "Mobile Number must be a 10-digit number";
                        }
                    }
                })}
                min="0"
                type="number" // Change type to text to prevent scientific notation for large numbers
                className="form-control"
                id="PhonenoInput"
                aria-describedby="PhonenoHelp"
                placeholder="Enter Mobile Number"
            />
            {errors.phoneNo && (
                <small id="PhonenoHelp" className="form-text text-muted">
                    {errors.phoneNo.message}
                </small>
            )}
                    </div>

                    <div class="mb-3">
                        <label for="emailInput" class="form-label">Registered Email</label>
                        <input
                {...register("emailAddress", {
                    required: "Email address is required",
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email regex
                        message: "Please enter a valid email address"
                    }
                })}
                type="email"
                className="form-control"
                id="emailInputInput"
                aria-describedby="emailInputHelp"
                placeholder="Enter Email"
            />
            {errors.emailAddress && (
                <small id="emailInputHelp" className="form-text text-muted">
                    {errors.emailAddress.message}
                </small>
            )}
                    </div>

                    <div className='row'>
                        {/* {radioSelect == 'Directors' ? ( */}
                        <div className="form-group col-md-6">
                            <label className="form-label" for="selectDirectors">Number of Directors</label>
                            <select class="form-select" {...register("noOfDirectors", { required: true, })} aria-label="Default select example">
                                <option selected value=''>Select</option>
                                {/* <option value="1">1</option> */}
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            {errors.noOfDirectors && (<small id="CorporatePartnerHelp" className="form-text text-muted">Please enter number of directors</small>
                            )}
                            {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                            {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[colourOptions[4], colourOptions[5]]}
                                isMulti
                                options={colourOptions}
                                onChange={handleChangeDirectors} 
                                name="noOfDirectors" // Handle changes
                            /> */}
                            {/* <small id="selectDirectorsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        {/* ) : ( */}
                        <div className="form-group col-md-6">
                            <label className="form-label" for="selectShareholders">Number of Shareholders</label>
                            <select class="form-select" {...register("noOfShareholders", { required: true, })} aria-label="Default select example">
                                <option selected value=''>Select </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </select>
                            {errors.noOfShareholders && (<small id="CorporatePartnerHelp" className="form-text text-muted">Please enter number of shareholders</small>
                            )}
                            {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                            {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                defaultValue={[colourOptions[4], colourOptions[5]]}
                                isMulti
                                options={colourOptions2}
                                onChange={handleChangeShareholders} 
                                name="noOfShareholders" // Handle changes
                            /> */}
                            {/* <small id="selectShareholdersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                        </div>
                        {/* )} */}
                    </div>

                    <div className="form-group col-md-12" style={{ textAlign: 'end', margin: '4px' }}>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </form>



            </div>


        </div>

    );
};

export default Others;
