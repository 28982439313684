import { useState } from "react";
import { GenerateToken } from "../helpers/GetRquest/getRquest";

const GenerateCode = () => {
    const [currentLocation] = useState(window.location.origin)
    const [generate, setGenerate] = useState(false)
    const [generatedToken, setGeneraedToken] = useState("")
    const [generateLink, setGenerateLink] = useState(false)
    // const [copy, setCopy] = useState('')
    const handleGenerateCode = () => {
        setGenerate(true)
        GenerateToken()
            .then((data) => {
                // console.log(data);
                if (data.token) {
                    setGenerate(false)
                    setGenerateLink(true)
                    setGeneraedToken(data.token)
                }
            })
            .catch((error) => console.log(error));
    }

    const handleCopyText = async (e) => {
        try {
            await window.navigator.clipboard.writeText(e.target.href);
            alert("Copied to clipboard!");
        } catch (err) {
            console.error(
                "Unable to copy to clipboard.",
                err
            );
            alert("Copy to clipboard failed.");
        }
    };
    
    return (
        <div className="container">
            <div className="row align-items-stretch no-gutters contact-wrap">
                <div className="col-md-12">
                    <div className="form h-100">
                        <h5 class="card-title" style={{
                            textAlign: 'center',
                            margin: '10px',
                            fontSize: '26px'
                        }}>Generate Link</h5>
                        {generate ? (
                            <div className="loader" style={{ margin: 'auto' }}></div>
                        ) : ('')}
                        <br />
                        <button style={{ margin: 'auto', display: 'block' }} className="btn btn-primary" onClick={handleGenerateCode}>Generate Code</button>
                        {generateLink ? (
                            <div className="p-3" style={{textAlign:'end'}}>
                                <a className="btn btn-primary" href={`${currentLocation}/main?token=${generatedToken}`} onClick={handleCopyText}>Copy Link</a>

                            </div>
                        ) : ('')}


                    </div>
                </div>
            </div>
        </div>

    )
}
export default GenerateCode;