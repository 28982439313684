import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';

const Private = () => {
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            proposedName: '',
            relevanceName: '',
            // relevanceAttachment: '',
            proposedRegisteredOffice: '',
            registeredOfficeAddress: '',
            registeredOfficeAddressAttachment: '',
            rentNocfromOwner: '',
            businessDesc: '',
            proposedAuthorisedCapital: '',
            proposedIssueCapital: '',
            otherDetails: '',
            phoneNo: '',
            emailAddress: '',
            noOfDirectors: '',
            noOfShareholders: '',

            fileRelevanceAttachment: '',
            fileRegisteredOfficeAddressAttachment: '',
            fileRentNocfromOwner: ''

            // "proposedName":"string",
            // "relevanceName":"string",
            // "relevanceAttachment":"string",
            // "proposedRegisteredOffice":"string",
            // "registeredOfficeAddress":"string",
            // "registeredOfficeAddressAttachment":"string",
            // "rentNocfromOwner":"string",
            // "businessDesc":"string",
            // "proposedAuthorisedCapital":"string",
            // "proposedIssueCapital":"string",
            // "otherDetails":"string",
            // "phoneNo":"string",
            // "emailAddress":"string",
            // "noOfDirectors":"string",
            // "noOfShareholders":"string"
        }
    })

    // const [radioSelect, setRadioSelect] = useState('Directors')

    const colourOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
    ]

    const colourOptions2 = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
    ]

    const onSubmit = async (data) => {
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
        // console.log(x.id);

        // data.attachment = base64String;
        // console.log('Form submit', data)

        // let fileRelevanceAttachment = ''
        let fileRegisteredOfficeAddressAttachment = ''
        let fileRentNocfromOwner = ''
        // *** UPLOAD TO AZURE STORAGE ***
        // console.log('base64StringRelevanceAttachment=----------',base64StringRelevanceAttachment);

        if (getValues('fileRelevanceAttachment')) {
            // *** UPLOAD TO AZURE STORAGE ***
            console.log('getValues(fileRelevanceAttachment)[0]',getValues('fileRelevanceAttachment')[0]);
            
            const blobUrl = await uploadFileToBlob(getValues('fileRelevanceAttachment')[0]).then((res) => {
                data.fileRelevanceAttachment = handlePriview(getValues('fileRelevanceAttachment')[0].name);

            });
        }

        if (getValues('fileRegisteredOfficeAddressAttachment')) {
            const blobUrl = await uploadFileToBlob(getValues('fileRegisteredOfficeAddressAttachment')[0]).then((res) => {
                data.fileRegisteredOfficeAddressAttachment = handlePriview(getValues('fileRegisteredOfficeAddressAttachment')[0].name);

            });
        }

        if (getValues('fileRentNocfromOwner')) {
            const blobUrl = await uploadFileToBlob(getValues('fileRentNocfromOwner')[0]).then((res) => {
                data.fileRentNocfromOwner = handlePriview(getValues('fileRentNocfromOwner')[0].name);

            });
        }

        x.privateCompany = {
            proposedName: data.proposedName,
            relevanceName: data.relevanceName,
            relevanceAttachment: data.fileRelevanceAttachment,
            proposedRegisteredOffice: data.proposedRegisteredOffice,
            registeredOfficeAddress: data.registeredOfficeAddress,
            registeredOfficeAddressAttachment: data.fileRegisteredOfficeAddressAttachment,
            rentNocfromOwner: data.fileRentNocfromOwner,
            businessDesc: data.businessDesc,
            proposedAuthorisedCapital: data.proposedAuthorisedCapital,
            proposedIssueCapital: data.proposedIssueCapital,
            otherDetails: data.otherDetails,
            phoneNo: data.phoneNo,
            emailAddress: data.emailAddress,
            noOfDirectors: parseInt(data.noOfDirectors),
            noOfShareholders: parseInt(data.noOfShareholders)
        };

        AddRegisterCustomer(x)
            .then((data) => {
                // console.log(data);
                localStorage.setItem('FormData', JSON.stringify(data))

                if (data.directors != '0') {
                    navigate('/director')
                }
                else if (data.shareholder != '0') {
                    navigate('/shareholder')
                }
                else {
                    alert('no director and shareholder selected')
                }

            })
            .catch((error) => console.log(error));

    }

    // Handle the change event
    const handleChangeDirectors = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('noOfDirectors', values)
    };

    const handleChangeShareholders = (selected) => {
        // console.log('Selected options:', selected);
        let values = [];
        selected.map((item) => {
            values.push(item.value)
        })
        // console.log('Selected options values:', values);
        setValue('handleChangeShareholders', values)
    };
    const [base64StringRegisteredOfficeAddressAttachment, setBase64StringRegisteredOfficeAddressAttachment] = useState('');
    const [base64StringRelevanceAttachment, setBase64StringRelevanceAttachment] = useState('');
    const [base64StringRentNocfromOwner, setBase64StringRentNocfromOwner] = useState('');
    const handleFileChangeRelevanceAttachment = async (e) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setBase64String(reader.result.split(',')[1]); // Get Base64 string without the data URL prefix
        //     };
        //     reader.readAsDataURL(file);
        // }
        // console.log(e.target.files[0]);

        if (!(e.target.files[0])) {
            return
        } else {
            // if(field=='relevanceAttachment'){
            setBase64StringRelevanceAttachment(e.target.files[0])
            // }
            // else if(field == 'registeredOfficeAddressAttachment'){
            //     setBase64StringRegisteredOfficeAddressAttachment(e.target.files[0])
            // }
        }
    };
    const handleFileChangeRegisteredOfficeAddressAttachment = async (e) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setBase64String(reader.result.split(',')[1]); // Get Base64 string without the data URL prefix
        //     };
        //     reader.readAsDataURL(file);
        // }

        if (!(e.target.files[0])) {
            return
        } else {
            // if(field=='relevanceAttachment'){handleFileChangeRegisteredOfficeAddressAttachment
            setBase64StringRegisteredOfficeAddressAttachment(e.target.files[0])
            // }
            // else if(field == 'registeredOfficeAddressAttachment'){
            //     setBase64StringRegisteredOfficeAddressAttachment(e.target.files[0])
            // }
        }
    };

    const handleFileChangeRentNocfromOwner = async (e) => {
        // const file = event.target.files[0];
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onloadend = () => {
        //         setBase64String(reader.result.split(',')[1]); // Get Base64 string without the data URL prefix
        //     };
        //     reader.readAsDataURL(file);
        // }

        if (!(e.target.files[0])) {
            return
        } else {
            // if(field=='relevanceAttachment'){handleFileChangeRegisteredOfficeAddressAttachment
            setBase64StringRentNocfromOwner(e.target.files[0])
            // }
            // else if(field == 'registeredOfficeAddressAttachment'){
            //     setBase64StringRegisteredOfficeAddressAttachment(e.target.files[0])
            // }
        }
    };

    return (
        <div className="container mt-2">
            <h5 class="card-title" style={{
                textAlign: 'center',
                margin: '10px',
                fontSize: '26px'
            }}>Private Company</h5>
            <form id="incorporationForm" className='row' onSubmit={handleSubmit(onSubmit)}>

                <div className="form-group row">
                    <div className='col-md-6'>
                        <label className="form-label" for="ProposedNameInput">Proposed Name</label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />

                        {errors.proposedName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Proposed Name is required</small>)}
                    </div>
                </div>
                <div className="form-group row">

                    <label className="form-label" for="ProposedNameInput">Name Relevance (Description Of Choosen Name)</label>
                    <div className='col-md-6'>
                        <input {...register("relevanceName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Name Relevance" />
                        {errors.relevanceName && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Name Relevance is required</small>)}


                    </div>
                    <div className='col-md-6'>
                        <input  type="file" 
                         accept="application/pdf"
                         {...register("fileRelevanceAttachment", {
                             required: true,
                             // validate: (value) => {
                             //     const file = value[0];
                             //     if (file && file.type !== "application/pdf") {
                             //         return 'Uploaded file must be a PDF!';
                             //     }
                             //     return true;
                             // }
                         })}

                         className="form-control"
                     />
                     {errors.fileRelevanceAttachment && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
                     </small>)}
                    </div>
                    {/* <div className='col-md-6'>
                        <label className="form-label"  for="ProposedNameInput"></label>
                        <input {...register("proposedName", {
                            required: true,
                        })} type="text" className="form-control" id="ProposedNameInput" aria-describedby="ProposedNameHelp" placeholder="Enter Proposed Name" />
                    </div> */}
                </div>

                <div className="form-group col-md-12">
                    <label className="form-label" for="ProposedRegisteredOfficeTextArea">Proposed Registered Office</label>
                    <textarea {...register("proposedRegisteredOffice", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Proposed Registered Office" />
                    {errors.proposedRegisteredOffice && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">Proposed Registered Office is required</small>
                    )}
                </div>

                <label className="form-label" for="NameRelevanceTextArea">Registered Office Address Proof (e.g. Electricity Bill, Mobile Bill, Tax Receipt—Not Older Than Two Months)</label>
                <div className="form-group col-md-6">
                    <input type="text" placeholder='Enter Registered Office Address Proof' {...register("registeredOfficeAddress", {
                        required: true,
                    })} className="form-control" id="file-input" for="file-input" />
                    {errors.registeredOfficeAddress && (<small id="ProposedRegisteredOfficeHelp" className="form-text text-muted">Registered Office Address Proof is required</small>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <input type="file"
                    accept="application/pdf"
                    {...register("fileRegisteredOfficeAddressAttachment", {
                        required: true,
                        // validate: (value) => {
                        //     const file = value[0];
                        //     if (file && file.type !== "application/pdf") {
                        //         return 'Uploaded file must be a PDF!';
                        //     }
                        //     return true;
                        // }
                    })}

                    className="form-control"
                />
                {errors.fileRegisteredOfficeAddressAttachment && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
                </small>)}
                </div>

                <div className="form-group col-md-6">
                    <label className="form-label" for="NameRelevanceTextArea">In Case of Lease/Rent: NOC from Owner</label>
                    <input type='file' 
                    accept="application/pdf"
                    {...register("fileRentNocfromOwner", {
                        required: true,
                        // validate: (value) => {
                        //     const file = value[0];
                        //     if (file && file.type !== "application/pdf") {
                        //         return 'Uploaded file must be a PDF!';
                        //     }
                        //     return true;
                        // }
                    })}

                    className="form-control"
                />
                {errors.fileRentNocfromOwner && (<small id="ProposedNameHelp" style={{ color: '#dc3545' }} className="form-text text-muted">Uploaded file must be a PDF!
                </small>)}
                </div>

                <div className="form-group col-md-6">
                    <label className="form-label" for="ObjTextArea">Main Object Clause: Business to be carried out</label>
                    <input type='text' {...register("businessDesc", {
                        required: true,
                    })} className="form-control" aria-describedby="ObjTextAreaHelp" id="ObjTextArea" rows="3" placeholder="Enter Main Object Clause" />
                    {errors.businessDesc && (<small id="ObjTextAreaHelp" className="form-text text-muted">Main Object Clause: Business to be carried out  is required</small>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label className="form-label" for="ProposedAuthorizedCapitalInput">Proposed Authorized Capital Minimum INR 1 Lakh</label>
                    <input
                         {...register("proposedAuthorisedCapital", {
                            required: "Proposed Authorized Capital is required",
                            validate: {
                              isNumber: (value) => !isNaN(value) || "Value must be a number",
                              isGreaterThanOrEqualToOneLakh: (value) => {
                                const numValue = parseFloat(value);
                                return (numValue >= 100000) || "Value must be at least 1 Lakh";
                              },
                            },
                          })}
                          type="text"
                          name="proposedAuthorisedCapital"
                          className="form-control"
                          id="ProposedNameInput"
                          aria-describedby="ProposedNameHelp"
                          placeholder="Enter Proposed Authorized Capital"
                        />
                        
                        {errors.proposedAuthorisedCapital && (
                          <small
                            id="ProposedNameHelp"
                            style={{ color: "#dc3545" }}
                            className="form-text text-muted"
                          >
                            {errors.proposedAuthorisedCapital.message}
                          </small>
                        )}
                </div>

                <div className="form-group col-md-6">
                    <label className="form-label" for="ProposedIssuedCapitalInput">Proposed Issued Capital and Subscribed Capital</label>
                    <input {...register("proposedIssueCapital", {
                        required: true,
                    })} type="number"  min="0" className="form-control" id="ProposedIssuedCapitalInput" aria-describedby="ProposedIssuedCapitalHelp" placeholder="Enter Proposed Issued Capital and Subscribed Capital" />
                    {errors.proposedIssueCapital && (<small id="ObjTextAreaHelp" className="form-text text-muted">Proposed Issued Capital and Subscribed Capital is required</small>
                    )}
                </div>

                <div className="form-group col-md-12">
                    <label className="form-label" for="ProposedRegisteredOfficeTextArea">Other Details</label>
                    <textarea {...register("otherDetails", {
                        required: true,
                    })} className="form-control" aria-describedby="ProposedRegisteredOfficeHelp" id="ProposedRegisteredOfficeTextArea" rows="3" placeholder="Enter Other Details" />
                    {errors.otherDetails && (<small id="ObjTextAreaHelp" className="form-text text-muted">Other Details  is required</small>
                    )}
                </div>

                <div className='row'>
                    <div className="form-group col-md-6">
                        <label className="form-label" for="PhonenoInput">Registered Mobile Number</label>
                        <input
                {...register("phoneNo", {
                    required: "Mobile Number is required",
                    validate: {
                        isValidNumber: value => {
                            const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
                            return phoneRegex.test(value) || "Mobile Number must be a 10-digit number";
                        }
                    }
                })}
                min="0"
                type="number" // Change type to text to prevent scientific notation for large numbers
                className="form-control"
                id="PhonenoInput"
                aria-describedby="PhonenoHelp"
                placeholder="Enter Mobile Number"
            />
            {errors.phoneNo && (
                <small id="PhonenoHelp" className="form-text text-muted">
                    {errors.phoneNo.message}
                </small>
            )}
                    </div>
                    <div className="form-group col-md-6">
                        <label className="form-label" for="emailInput">Registered Email ID</label>
                        <input
                {...register("emailAddress", {
                    required: "Email address is required",
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Basic email regex
                        message: "Please enter a valid email address"
                    }
                })}
                type="email"
                className="form-control"
                id="emailInputInput"
                aria-describedby="emailInputHelp"
                placeholder="Enter Email"
            />
            {errors.emailAddress && (
                <small id="emailInputHelp" className="form-text text-muted">
                    {errors.emailAddress.message}
                </small>
            )}
                    </div>
                </div>

                <div className='row'>
                    {/* {radioSelect == 'Directors' ? ( */}
                    <div className="form-group col-md-6">
                        <label className="form-label" for="selectDirectors">Number of Directors</label>
                        <select class="form-select" {...register("noOfDirectors", { required: true, })} aria-label="Default select example">
                            <option value=''>Select</option>
                            {/* <option value="1">1</option> */}
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        {errors.noOfDirectors && (<small id="emailInputHelp" className="form-text text-muted">Please select number of Directors</small>
                        )}
                    </div>

                    {/* <div className="form-group col-md-6">
                        <label className="form-label"  for="selectDirectors">Number of Directors (minimum 2)</label> */}
                    {/* <input  type="text" className="form-control" id="selectDirectors" aria-describedby="selectDirectorsHelp" placeholder="Select Multiple Directors" /> */}
                    {/* <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions}
                            onChange={handleChangeDirectors}
                            name="noOfDirectors" // Handle changes
                        /> */}
                    {/* <small id="selectDirectorsHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    {/* </div> */}
                    {/* ) : ( */}
                    <div className="form-group col-md-6">
                        <label className="form-label" for="selectShareholders">Number of Shareholders</label>
                        <select class="form-select" aria-label="Default select example" {...register("noOfShareholders", { required: true, })}>
                            <option value=''>Select </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                        </select>
                        {errors.noOfShareholders && (<small id="emailInputHelp" className="form-text text-muted">Please select number of Shareholders</small>
                        )}
                        {/* <input {...register("noOfShareholders", {
                            required: true,
                        })} type="text" className="form-control" id="selectShareholders" aria-describedby="selectShareholdersHelp" placeholder="Select Multiple Shareholders" /> */}
                        {/* <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            defaultValue={[colourOptions[4], colourOptions[5]]}
                            isMulti
                            options={colourOptions2}
                            onChange={handleChangeShareholders}
                            name="noOfShareholders"  // Handle changes
                        /> */}
                        {/* <small id="selectShareholdersHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>
                    {/* )} */}
                </div>

                <div className="form-group col-md-12" style={{ textAlign: 'end', marginTop:'10px' }}>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </div>

            </form>

        </div>)
}

export default Private