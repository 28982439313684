import axios from "axios";
import { API_URL } from "../config";




export const GenerateToken = async (params) => {

    try{
        const res = await axios.get(`${API_URL}/MainEnquiryForm/GenerateToken`, {
        // params: params,
        });
        return res?.data;
    }catch (error){
        console.log(error);
    }
}