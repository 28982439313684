import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AddRegisterCustomer, AddRegisterCustomerLogin } from "../helpers/PostRquest/postRquest";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Login = () => {
const navigate = useNavigate()
const [isLoader, setIsLoader] = useState(false)
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            userName: "",
            password: ""
        }
    })

    const onSubmit = (data) => {
        setIsLoader(true)
        // console.log('Form submit', data)
        AddRegisterCustomerLogin(data)
            .then((data) => {
                // console.log(data);  
                if (data.message == 'Login successful') {
                   
                    localStorage.setItem('UserData', JSON.stringify(data.message))
                    const myTimeout = setTimeout( window.location.href='/generate_code', 3000);
                    setIsLoader(false)
                   
                }
                else{
                   
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Please enter correct username and password"
                    });
                }
            })
            .catch((error) => {console.log(error)
              alert("please enter correct username and password")});
    }

    return (<>
        <div className="container" style={{ maxWidth: '485px' }}>
            <div className="row align-items-stretch no-gutters contact-wrap">
                <div className="col-md-12">
                    <div className="form h-100">
                        <h5 class="card-title" style={{
                            textAlign: 'center',
                            margin: '10px',
                            fontSize: '26px'
                        }}>User Login</h5>
                         {isLoader ? (
                <div className='container'>
                    <div className=" loader"></div>
                </div>
            ) : (
                        <form className="mb-5" id="contactForm" name="contactForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-12 form-group mb-3">
                                    <label for="" className="col-form-label">User Name *</label>
                                    <input type="text" className="form-control" name="name" id="name"
                                        placeholder="Your username" {...register("userName", {
                                            required: true,
                                        })} />
                                    {errors.userName && (<small id="" className="form-text text-muted">Please enter username</small>)}

                                </div>
                                <div className="col-md-12 form-group-mb-3">
                                    <label for="" className="col-form-label">Password *</label>
                                    <input type="password" className="form-control" name="Password" id="Mobile"
                                        placeholder="Your password" {...register("password", {
                                            required: true,
                                        })}
                                    />
                                    {errors.password && (<small id="" className="form-text text-muted">Please enter password</small>)}

                                </div>

                            </div>

                            <div className="row p-3">
                                <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                    <span className="submitting"></span>
                                </div>
                            </div>
                        </form>
            )}

                        <div id="form-message-warning mt-4"></div>
                        {/* <div id="form-message-success">
                            Your message was sent, thank you!
                        </div> */}

                    </div>
                </div>
            </div>


        </div>




    </>
    )
}
export default Login;